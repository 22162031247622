import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/image-component.js");
import(/* webpackMode: "eager" */ "/vercel/path0/src/app/_components/BackgroundImageLayout.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/src/app/_components/Header/index.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/src/app/_components/SmartLink/index.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/src/app/[locale]/home/home1.circle.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/src/app/[locale]/home/home1.square.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/src/app/[locale]/home/home2.circle.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/src/app/[locale]/home/home2.square.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/src/app/assets/images/bg_building.png");
import(/* webpackMode: "eager" */ "/vercel/path0/src/app/assets/images/bg_casino.png");
import(/* webpackMode: "eager" */ "/vercel/path0/src/app/assets/images/bg_child.png");
import(/* webpackMode: "eager" */ "/vercel/path0/src/app/assets/images/bg_draw_1.png");
import(/* webpackMode: "eager" */ "/vercel/path0/src/app/assets/images/bg_draw_2.png");
import(/* webpackMode: "eager" */ "/vercel/path0/src/app/assets/images/bg_lucky.png");
import(/* webpackMode: "eager" */ "/vercel/path0/src/app/assets/images/bg_merry_ch.png");
import(/* webpackMode: "eager" */ "/vercel/path0/src/app/assets/images/bg_mid_autum.png");
import(/* webpackMode: "eager" */ "/vercel/path0/src/app/assets/images/bg_noel.png");
import(/* webpackMode: "eager" */ "/vercel/path0/src/app/assets/images/bg_overlay_1.png");
import(/* webpackMode: "eager" */ "/vercel/path0/src/app/assets/images/bg_overlay_2.png");
import(/* webpackMode: "eager" */ "/vercel/path0/src/app/assets/images/bg_theme_small_1.png");
import(/* webpackMode: "eager" */ "/vercel/path0/src/app/assets/images/bg_theme_small_2.png");
import(/* webpackMode: "eager" */ "/vercel/path0/src/app/assets/images/bg_theme_small_3.png");
import(/* webpackMode: "eager" */ "/vercel/path0/src/app/assets/images/bg_theme_small_4.png");
import(/* webpackMode: "eager" */ "/vercel/path0/src/app/assets/images/bg_theme_small_5.png");
import(/* webpackMode: "eager" */ "/vercel/path0/src/app/assets/images/bg_theme_small_6.png");
import(/* webpackMode: "eager" */ "/vercel/path0/src/app/assets/images/bg_theme_small_7.png");
import(/* webpackMode: "eager" */ "/vercel/path0/src/app/assets/images/bg_theme_small_8.png");
import(/* webpackMode: "eager" */ "/vercel/path0/src/app/assets/images/bg_theme_small_9.png");
import(/* webpackMode: "eager" */ "/vercel/path0/src/app/assets/images/bg_valen.png");
import(/* webpackMode: "eager" */ "/vercel/path0/src/app/assets/images/bg_woman.png");
import(/* webpackMode: "eager" */ "/vercel/path0/src/app/assets/images/logox2.png")